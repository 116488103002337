.container{
  font-family: rubik;
}

.container-fluid{
  font-family: rubik;
}

.sub_header_icon{
    font-size: 40px;
}
.nav-link:hover{
    color: rgb(48, 38, 246);
}
.social_icons{
    color: rgb(153, 22, 127);
    margin-left: 30px;
}

.bg_image{
    background-image: url(../public/images/main2.jpg)
}

.content_count{
    display: flex;
    flex-direction: column;
align-items: center;
}

.bring_bg{
    background-color: rgb(170, 207, 223);
    border-radius: 1000px;
    color: rgb(33, 28, 28);
}

.image_width{
    width: 100%;
}

.bg_about_page{
    background-color: #111C55;
    color: white;
}
.bg_image {
    background-size: cover;
    background-position: center;
}

/* Home page ka Get a quote button*/
.custom-button {
    background: linear-gradient(135deg, #6f42c1, #e83e8c);
    border: none;
    color: #fff;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 100px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease, transform 0.3s ease;
    cursor: pointer;
}

.custom-button:hover {
    background: linear-gradient(135deg, #5a30a1, #d92e7b);
    transform: scale(1.05);
}

.custom-button:active {
    transform: scale(0.98);
}

/* End of quote button */


/*Start hover on carddddd*/
.card {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  
  .card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.2) 20%, transparent 50%);
    opacity: 0;
    transition: opacity 0.3s, transform 0.6s;
    pointer-events: none; 
    z-index: 1; 
  }
  
  .card:hover::before {
    opacity: 0.4;
    transform: translate(-10px, -10px);
  }
  
  .card-body {
    position: relative; 
    z-index: 2;
  }
  
  .card-body button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.3s;
    z-index: 2; 
  }
  
  .card-body button:hover {
    background-color: #1c4ec3;
    transform: scale(1.05);
  }
  /* End*/


  

  .nav-item .dropdown-menu {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.nav-item.dropdown:hover .dropdown-menu {
    display: block;
}

.dropdown-item {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-item:hover {
    background-color: #f1f1f1;
}




/* Start of section one animation for reference commenttt*/

.container-fluid {
  width: 100%;
  padding: 0;
  margin: 0;
}


h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  animation: fadeIn 1.5s ease-in-out forwards;
}


p {
  animation: slideIn 0.5s ease-in-out forwards;
  opacity: 0;
}

p:nth-child(2) { animation-delay: 0.3s; }
p:nth-child(3) { animation-delay: 0.6s; }
p:nth-child(4) { animation-delay: 0.9s; }
p:nth-child(5) { animation-delay: 1.2s; }
p:nth-child(6) { animation-delay: 1.5s; }
p:nth-child(7) { animation-delay: 1.8s; }


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
/* End of section one animation for reference commenttt*/











